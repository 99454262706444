body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.PhoneInput input {
    color: currentcolor;
    background-image: none;
    background-color: initial;
    -webkit-tap-highlight-color: transparent;
     font: inherit;
     letter-spacing: inherit;
     color: currentcolor;
     border: 1px solid rgba(255, 255, 255, 0.23);
     border-radius: 4px;
     box-sizing: content-box;
     background: none;
     height: 1.4375em;
     margin: 0px;
     -webkit-tap-highlight-color: transparent;
     display: block;
     min-width: 0px;
     width: 100%;
     animation-name: mui-auto-fill-cancel;
     animation-duration: 10ms;
     /* padding: 16.5px 14px; */
    padding: 15.5px 13px;
}
.PhoneInput input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}
.PhoneInput input:hover:not(:focus) {
    outline: 0px;
    padding: 15.5px 13px;
    border: 1px solid white !important;
}
.PhoneInput input:focus {
    outline: 0px;
    padding: 14.5px 12px;
    border: 2px solid rgb(144, 202, 249);
}